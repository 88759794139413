<template>
  <itemCard type="primary" :data="computedData" :action="action" :actionText="actionText" />
</template>

<script>
export default {
  components: {
    itemCard: () => import("@/modules/base/components/itemCard/itemCard.vue"),
  },
  props: {
    data: Object
  },
  computed: {
    providerId() {
      return this.$store.getters[`member/providerId`]
    },
    computedData() {
      return [
        {
          text: "客戶",
          value: this.data.name,
        },
        {
          text: "聯絡人",
          value: this.data.member_name,
        },
        {
          text: "合作狀態",
          value: this.$t(`provider.status.detail.${this.data.status}`),
          class: this.statusColorClass,
        },
      ];
    },
    statusColorClass() {
      if (
        this.data.status ==
        this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REJECT
      )
        return "red--text";
      return "primary--text";
    },
    actionText() {
      if (
        this.data.status ==
        this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_REQUEST
      )
        return "回覆";
      return "查看";
    },
  },
  methods: {
    action() {
      this.$router.push({
        name: "client-info",
        params: { storeId: this.data.store_id, providerId: this.providerId },
      });
    },
  },
};
</script>